import { createAction, props } from '@ngrx/store';

import { GdprPolicyText, GdprPolicyType } from '../../models';

export const loadRequest = createAction(
  '[Gdpr Privacy] Load Request',
  props<{ propertyId: number; gdprPrivacyType: GdprPolicyType }>(),
);

export const loadSuccess = createAction(
  '[Gdpr Privacy] Load Success',
  props<{ response: { data: GdprPolicyText[]; type: GdprPolicyType } }>(),
);

export const loadFailure = createAction(
  '[Gdpr Privacy] Load Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Gdpr Privacy] Update Request',
  props<{
    data: {
      property_id: number;
      languages: Record<string, { description: string }>;
      type: GdprPolicyType;
    };
  }>(),
);

export const updateSuccess = createAction(
  '[Gdpr Privacy] Update Success',
  props<{ response: { data: GdprPolicyText[]; type: GdprPolicyType } }>(),
);

export const updateFailure = createAction(
  '[Gdpr Privacy] Update Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Gdpr Privacy] Delete Request',
  props<{
    propertyId: number;
    gdprPrivacyType: GdprPolicyType;
  }>(),
);

export const deleteSuccess = createAction(
  '[Gdpr Privacy] Delete Success',
  props<{
    response: {
      type: GdprPolicyType;
    };
  }>(),
);

export const deleteFailure = createAction(
  '[Gdpr Privacy] Delete Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Gdpr Privacy] Reset State');
