import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { GdprPrivacyService } from '../../services/gdpr-privacy.service';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class GdprPrivacyStoreEffects {
  constructor(
    private dataService: GdprPrivacyService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private translate: TranslateService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ propertyId, gdprPrivacyType }) =>
        this.dataService.load(propertyId, gdprPrivacyType).pipe(
          map((response: IResponseSuccess) => {
            return fromActions.loadSuccess({
              response: {
                data: response.data?.[0]?.translations,
                type: gdprPrivacyType,
              },
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(({ data }) =>
        this.dataService.update(data).pipe(
          map((response: IResponseSuccess) => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.update_success', {
                param: this.translate.instant(`${data.type}_policy`),
              }),
              type: 'success',
            });
            return fromActions.updateSuccess({
              response: {
                data: response.data[0],
                type: data.type,
              },
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateFailure(error));
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(({ propertyId, gdprPrivacyType }) =>
        this.dataService.delete(propertyId, gdprPrivacyType).pipe(
          map((response: IResponseSuccess) => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.delete_success', {
                param: this.translate.instant(`${gdprPrivacyType}_policy`),
              }),
              type: 'success',
            });
            return fromActions.deleteSuccess({
              response: { type: gdprPrivacyType },
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteFailure(error));
          }),
        ),
      ),
    ),
  );
}
