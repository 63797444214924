import { Action, createReducer, on } from '@ngrx/store';
import { GdprPolicyText, GdprPolicyType } from '../../models';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { response: { data, type } }) => {
    return {
      ...state,
      data: { [type]: data } as Record<GdprPolicyType, GdprPolicyText[]>,
      isLoading: false,
      error: null,
    };
  }),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.updateRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.updateSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(fromActions.updateFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.deleteRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.deleteSuccess, (state, { response: { type } }) => {
    const translations = state.data?.[type].map((translation) => {
      return {
        ...translation,
        body: null,
      };
    });

    return {
      ...state,
      data: { [type]: translations } as Record<
        GdprPolicyType,
        GdprPolicyText[]
      >,
      isLoading: false,
      error: null,
    };
  }),
  on(fromActions.deleteFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.resetState, () => fromState.initialState),
);

export function gdprPrivacyReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
