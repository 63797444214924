import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { GdprPolicyType } from '../models';

@Injectable({
  providedIn: 'root',
})
export class GdprPrivacyService {
  constructor(private http: HttpClient) {}

  load(property_id: number, type: GdprPolicyType) {
    return this.http.get(`property/${property_id}/gdpr/${type}`);
  }

  update(data: {
    property_id: number;
    languages: Record<string, { description: string }>;
    type: GdprPolicyType;
  }) {
    const { property_id, type } = data;

    return this.http.put(`property/${property_id}/gdpr/${type}`, data);
  }

  delete(property_id: number, type: GdprPolicyType) {
    return this.http.delete(`property/${property_id}/gdpr/${type}`);
  }
}
