import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GdprPolicyType } from '../../models';

import { State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const selectState = createFeatureSelector<State>('gdprPrivacy');

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectGdprData = (type: GdprPolicyType) =>
  createSelector(selectState, (state) => state?.data?.[type]);
