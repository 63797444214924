import { GdprPolicyText, GdprPolicyType } from '../../models';

export interface State {
  data: Record<GdprPolicyType, GdprPolicyText[]>;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = {
  isLoading: false,
  error: null,
  data: null,
};
